import React from "react"
import { navigate } from "gatsby-link"
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ContactItems from '../../components/contactItems'
import styled from 'styled-components'



const Contact = styled.div`
    gap: 3em 3em;
    padding: 0 2em;
  h1{
      padding: 1em 0 ;
      margin: 0;
  }
  p {
    margin-bottom:3em;
  }
  `

const FormContainer = styled.div`
    padding: 0 2em ;
    max-width:1120px;
    margin: 0 auto ;

    h4 {
    font-size: 1.2em;
    max-width: 750px;
    margin: 1em 0;
    line-height:1.3;
  }

  a {
    margin: 2em 0;
    display: block;
    padding: 10px 0;
    width: 100%;
    color: #000;
    font-size: 1.2em;
  }

    h2 {
      padding:1em 0;
    }

    input, button, textarea{
        width:100%;
        margin:10px 0;
        padding:10px 0;
      }

      textarea {
        min-height:300px;
        outline:none;
      }
    button {
      border:none;
      font-family:'Gotham-Medium';
      color:${props => props.theme.green};
      background:white;
      cursor:pointer;
      max-width: 150px;
      margin-bottom:2em;
      border-radius:5px;
      transition: all 0.3s ease 0s;
      border:1px solid ${props => props.theme.green};

      &:hover {
        color:white;
        background: ${props => props.theme.green};
      }
    }  

    .row1 {
      display:grid;
      grid-template-columns:1fr 1fr 1fr;
      grid-gap:1em;


      @media screen and (max-width: 750px) {
        grid-template-columns:1fr;
    }

    }

    .row2 {
      display:grid;
      grid-template-columns:1fr 1fr ;
      grid-gap:1em;
      @media screen and (max-width: 750px) {
        grid-template-columns:1fr;
    }
    }
`

const Choices = styled.fieldset`
  border:none;
  display: inline-block;
  margin-right:1em;
   
  input {
    width:40px;
  }
  label {
    padding:7px;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  constructor(props) {
    super(props)
    this.transitionEnd = this.transitionEnd.bind(this)
    this.mountStyle = this.mountStyle.bind(this)
    this.unMountStyle = this.unMountStyle.bind(this)
    this.state ={ //base css
      show: true,
      selectedIndex: 0,
      isValidated: false,
      style :{
        opacity: 0,
        transition: 'all .4s ease',
      }
    }
  }
  
  componentWillReceiveProps(newProps) { // check for the mounted props
    if(!newProps.mounted)
      return this.unMountStyle() // call outro animation when mounted prop is false
    this.setState({ // remount the node when the mounted prop is true
      show: true
    })
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  unMountStyle() { // css for unmount animation
    this.setState({
      style: {
        opacity: 0,
        transition: 'all 1s ease',
      }
    })
  }
  
  mountStyle() { // css for mount animation
    this.setState({
      style: {
        opacity: 1,
        transition: 'all 1s ease',
      }
    })
  }
  
  componentDidMount(){
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  transitionEnd(){
    if(!this.props.mounted){ // remove the node on transition end when the mounted prop is false
      this.setState({
        show: false
      })
    }
  }

  render() {
    return (
      <Layout>
    <SEO title="Contact Us" />
    <Contact style={this.state.style} onTransitionEnd={this.transitionEnd}>
        <section className="section">
       
        {/* <form
        style={{
          minHeight:'400px',
          backgroundRepeat:'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginLeft:'calc(-50vw + 50% - 8px)',
          position:'relative', 
          width: '100vw',
          
        }}

          name="contact"
          method="post"
          action="/contact/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        > */}
        <FormContainer>
 
        <h1>Contact Us</h1>
        <h4> If you are looking for investment, please include a PDF of your investment deck, a brief description of how much you are looking to raise, how much you have raised historically, and why your company is a relevant investment target for our firm.</h4>
            <a href="mailto:info@tamarisc.com">info@tamarisc.com</a>

          {/* The `form-name` hidden field is required to support form submissions without JavaScript
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div className="row1">
          <div className="field">
            <div className="control">
              <input placeholder="First Name"  className="input" type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input placeholder="Last Name" className="input" type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <input placeholder="Title" className="input" type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} />
            </div>
          </div>
          </div>
          

          <div className="row2">

          <div className="field">
              <div className="control">
                <input placeholder="Email" className="input" type={"email"} name={"email"} onChange={this.handleChange} id={"email"} required={true} />
              </div>
          </div>

       

          <div className="field">
              <div className="control">
                <input placeholder="Country" className="input" type={"country"} name={"country"} onChange={this.handleChange} id={"country"} required={false} />
              </div>
          </div>
          
          </div>

 
          <div className="field">
           <h2>How did you hear about us?</h2>
            <Choices>
              <input type="radio" name="action" id="referral" value="referral" /><label for="referral">Referral</label>
            </Choices>
            <Choices>
            <input type="radio" name="action" id="wordofmouth" value="wordofmouth"  /><label for="wordofmouth">Word of Mouth (Friend, Family or Coworker)</label>
            </Choices>
            <Choices>
              <input type="radio" name="action" id="searchengine" value="searchengine" /><label for="searchengine">Search Engine</label>
            </Choices>


          </div>
          

          <div className="field">
            <div className="control">
              <textarea placeholder="How can we help you?" className="textarea" name={"message"} onChange={this.handleChange} id={"message"} required={true} />
            </div>
          </div>
          <div className="field">
            <button className="button is-link" type="submit">Submit Form</button>
          </div> */}
          </FormContainer>
        {/* </form> */}
     
        </section>
    </Contact>

      </Layout>
    );
  }
}
