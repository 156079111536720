import React from 'react'
import { Link } from "gatsby";
import styled from 'styled-components';

const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-gap:4em;

    @media screen and (max-width: 750px) {
    grid-template-columns: repeat(2,auto);


    }

    span {
        display: block;
    }
    

    svg {
        max-width:50px;
    }

    p {
        margin-bottom:0 !important;
    }
`



const ContactItems = class extends React.Component {
    render() {
      return (
  
    <Items>
        <div>
        <span>Call Us </span>
        <p>+1-212-267-7283</p>
        </div>
       
    </Items>
  )}
}

export default ContactItems
